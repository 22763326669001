import React from 'react';

import Header from '../Header'
import Footer from '../Footer'
import { Container, Content, Buttons, Family, Head1, Head2, Paragraph, Button } from './styles';
import { AiFillAndroid, AiFillApple } from "react-icons/ai";

export default () => {
    return (
        <Container>
            <Content>
                <Header />
                <Head1>إتفاقية الإستخدام</Head1>
                <Paragraph>
من خلال دخولك أو استخدام للخدمة فهذا يعني:
أنت تتفق علي أنك قد قرأت وفهمت وتوافق أن تحكمك شروط خدمة "البيت يجمعنا" (“الشروط” أو “شروط الخدمة”) والتي يتم تحديثها من وقت لآخر مما يحفظ استخدامك للخدمة. وأنت مصرح لك بإستخدام الخدمة كما هو موضح من خلال هذه الشروط.
<br />
<br />
تتفق علي أن هذه الشروط تقضي بأن كل الخلافات بينك وبين "البيت يجمعنا" سيتم حلها بواسطة التحكيم الملزم. أنت توافق أن تتخلي عن حقك في الذهاب للمحكمة لتطلب حقوقك أو تدافع عنها طبقا ً لهذا العقد (بخلاف الأمور التي لم تشملها الشروط)
<br /><br />
 

توافق علي أن يتم جمع واستخدام ومشاركة ونقل معلوماتك، بما في ذلك ولكن ليس حكرا ً علي: موقعك الجغرافي كما هو موضح في سياسة الخصوصية الخاصة بمنصة "البيت يجمعنا" والتي يتم تحديثها من وقت لآخر والتي يشملها هذا الإتفاق من خلال الإشارة إليها هنا.
<br />
تتفق أن الموقع الإلكتروني قد يشتمل علي محتوي خاص بطرف ثالث وأن الشركة ليست مسؤولة عن مثل هذا المحتوي.
<br /><br />
 

 

تتفق أن الخدمات المقدمة تقدم كما هي بدون أي ضمانات من أي نوع ومسؤولية الشركة تجاهك محدودة بالإضافة إلي أن دخولك علي الخدمة واستخدامك لها يعتمد بشكل صريح علي إتفاقك مع هذه الشروط واتباعك لها. من خلال دخولك، تصفحك، أو استخدامك للخدمة فأنت تقر بأنك قد قرأت هذه الشروط وموافق أن تحتكم بها وإليها.
<br />
من يحق له استخدام الخدمة<br /><br />
1.1 الخدمة ليست متوفرة لأشخاص تحت سن الثمانية عشرة أو لأي شخص تم إيقافه أو حذفه من الخدمة بواسطة شركة راز للمعارض. بدخولك، تصفحك، وإستخدامك للخدمة فأنت تعلن بأنك علي الأقل في سن الثمانية عشرة ولم يتم إيقافك أو حذفك من الخدمة من قبل.
<br /><br />
1.2 إذا كنت تستخدم الخدمة ممثلا ً لشركة، هيئة، أو مؤسسة فأنت تعلن وتضمن بأنك ممثل مصرح له من هذه الجهة ولديك السلطة التي تجعلك تلزمها بهذه الشروط، وتوافق أيضا ً أن تلتزم بهذه الشروط بالنيابة عن تلك المؤسسة.
<br /><br />
2. الخدمة<br /><br />
2.1 منصة "البيت يجمعنا" هي حل إبداعي يصل ما بين الزبائن/العملاء وبين مغاسل التنظيف وخدمات التنظيف الجاف من خلال عرضه لخدمة الإستلام/التسليم. نموذج العمل الخاص بنا بسيط: منصة "البيت يجمعنا" تصل ما بين العملاء والمغاسل والسائقين من خلال تطبيق الهاتف والموقع الإلكتروني.
<br />
منصة "البيت يجمعنا" قد تجري فحص لخلفية موفري الخدمة ولكنها لا تستطيع أن تضمن دقة أو إكتمال أي فحص ولا تضمن أن مزود الخدمة مؤهل ليؤدي وظيفة بعينها ومسؤولية ذلك تقع علي عاتق مزود الخدمة نفسه، وليس علي شركة راز للمعارض مسؤولية تحديد إذا كان مؤهلا ً وقادراً علي إنهاء وظيفة بعينها. وحتي إذا كانت دقيقة فإن فحوصات الخلفية لا تتوقع بالضرورة السلوك والأداء المستقبلي. عندما تطلب خدمة من أحد مزودي الخدمة يجب أن تستخدم المنطق السليم والإحتياط لكي تحمي ممتلكاتك وسلامتك الشخصية وممتلكات الآخرين وسلامتهم الشخصية، بما يشمل الإشراف علي مزودي الخدمة بنفس الأسلوب الذي قد تشرف به علي أي شخص آخر لا تعرفه يقوم بتقديم خدماته لك.
<br />
2.2 "البيت يجمعنا" لا تتملك أي طلب عملية، صور، أو مواد أخري (“مدخلات” تدخلها من خلال "البيت يجمعنا") ولكن يجب أن تؤكد وتعلن وتضمن أنك المالك -أو تملك التراخيص المطلوبة، الحقوق، التصاريح، والموافقات- لكي توفر هذه المواد لتستخدمها "البيت يجمعنا" بما يناسب الخدمة.. وتوافق أن تمنح الشركة رخصة غير حصرية مجانية عالمية غير قابلة للإلغاء دائمة وقابلة للتحويل لكي تستخدم وتوزع وتعيد إنتاج وتعدل وتطور وتعرض وتؤدي بشكل عام وتستغل هذه المدخلات ذات العلاقة بالخدمة المقدمة.
<br />

التسجيل<br /><br />
3.1 يجب أن تنشأ حسابا ً لكي تستخدم بعض الخواص المعينة بالخدمة. أنت مسؤول عن تأمين اسم المستخدم وكلمة السر التي تستخدمها للدخول إلي أي جزء آمن ومحصن بكلمة سر في الخدمة. يجب ألا تصرح بكلمة السر الخاصة بك لأي طرف ثالث. أنت بمفردك المسؤول عن أي نشاطات أو أعمال تحدث تحد اسم المستخدم وكلمة السر الخاصين بك بغض النظر إن كنت صرحت بمثل هذه الأفعال أو لا. يجب أن تخبرنا بشكل عاجل بأي استخدام غير مصرح له لإسم المستخدم أو كلمة السر الخاصين بك.
<br /><br />
3.2 عندما تربط منصة "البيت يجمعنا" بخدمة يوفرها طرف ثالث (مثال: فيسبوك أو تويتر) فأنت تعطينا التصريح للدخول واستخدام معلوماتك الموجودة علي تلك الخدمة كما تسمح الخدمة نفسها كذلك تسمح بحفظ معلومات دخولك لتلك الخدمة ونشر معلومات نيابة عنك من خلالها.
<br /><br />
3.3 يجب أن توفر معلومات صحيحة، دقيقة، سارية، وكاملة عن نفسك كما تتطلب أي استمارة تملؤها كجزء من عملية التسجيل بالخدمة وإنشاء حسابك. إذا سمحنا لك بالدخول لمنصة "البيت يجمعنا" بإستخدام معلومات تسجيلك الخاصة بطرف ثالث (مثل فيسبوك وتويتر) يجب أن تضمن أن كل المعلومات التي توفرها لنا من خلال ذلك الطرف الثالث (مثال: اسمك، بريدك الإلكتروني، عنوانك، جنسك، أو تاريخ ميلادك) هي معلومات صحيحة، دقيقة وكاملة. إذا وفرت لنا معلومات غير صحيحة، عير دقيقة، غير سارية، مخادعة، مضللة أو ناقصة فنحن نحتفظ بالحق لكي نحذف حسابك ورفض أي استخدام لك للخدمة بالحاضر والمستقبل. أنت مسؤول عن المحافظة علي معلومات حسابك سارية ودقيقة ونحن لا نتحمل المسؤولية الفشل في توصيل التنبيهات التي تنتج عن وجود معلومات حساب غير صحيحة.
<br /><br />
التكاليف<br /><br />
4.1 عندما تقوم بالطلب فالشركة سوف تساعد في تسهيل التحويل من خلال توفير آلية لدفع المبالغ الخاصة بكل طلب “التكاليف”. تكاليف الطلبات ليست قابلة للإسترداد وسيتم محاسبتك بها بما يطابق جدول الرسوم الخاص بالشركة الموجود في وقت القيام بالطلب، والذي قد يتم تحديثه من وقت لآخر بواسطة منصة "البيت يجمعنا" من الآن فصاعدا ً. أنت مسؤول عن أي وكل الضرائب (ماعدا الضرائب الخاصة بدخل شركة راز للمعارض) المتعلقة بـ (أ) الخدمات التي توفرها منصة "البيت يجمعنا". (ب) الخدمات المتعلقة بالأعمال التي يقوم بها موفرو الخدمة.
<br /><br />
4.2 "البيت يجمعنا" ستحاسبك علي تكاليف الطلبات من خلال طريقة الدفع المحددة في حسابك (مثال: بطاقة الائتمان). إذا دفعت أي تكاليف مستحقة عن طريق بطاقة الائتمان فشركة راز للمعارض قد تطلب تصريح مسبق لتلك البطاقة المرتبطة بحسابك كي تتأكد أن البطاقة صالحة وبها ما يكفي من الرصيد المالي لتغطية الطلب الذي تقوم بعمله. إذا قمت بالدفع لأي من منتجاتنا أو خدماتنا علي التطبيق الخاص بنا فإن المعلومات التي سيطلب منك إدخالها سيتم إرسالها مباشرة لموفر خدمة الدفع الخاص بنا من خلال إتصال آمن. أنت تعطي السلطة وتصرح لحساب تلك البطاقة الإئتمانية بدفع أي مبالغ يتم وصفها هنا وتعطي السلطة وتصرح لمنصة "البيت يجمعنا" أن تأخذ المبالغ الموصوفة هنا من حساب تلك البطاقة الإئتمانية.

أنت توافق علي أن تعطي شركة راز للمعارض معلومات محدثة تخص بطاقتك الائتمانية وحسابك علي حسب طلب ورغبة الشركة وفي أي وقت تكون فيه المعلومات التي تم توفيرها مسبقا ً غير صحيحة. في حال لم تتمكن منصة "البيت يجمعنا" من خصم التكاليف من البطاقة الائتمانية بسبب عدم توفر الرصيد المالي الكافي فإن الشركة تحتفظ بحقها في الذهاب بالأمر للقضاء والإتصال بأي وكالة لتحصيل الديون. أنت تفهم وتوافق علي أنك ستكون مسؤولا ً عن كل التكاليف القانونية والإدارية. حامل البطاقة يجب أن يحتفظ بنسخة لتاريخ التعاملات وسياسة وقوانين التاجر.

 
<br /><br />
الدفع
<br /><br />
إذا اخترت أن تدفع من خلال بوابة الدفع الإلكتروني بإستخدام بطاقة الائتمان/الدين فإن المبلغ المطلوب سيتم خصمه لحظيا ً بمجرد عمل الطلب.
<br />
إذا اخترت أن تدفع باستخدام بطاقة الائتمان/الدين علي المنصة نفسها فالعملية ستتم بواسطة "البيت يجمعنا" كممثل عن موفر الخدمة.
<br />
المستخدم عرضة لأن يدفع نقدا ً عند التوصيل أي تكاليف إضافية يطلبها موفر الخدمة نتيجة لعمل “طلب خاص” أو ” الطلب العام” بواسطة المستخدم.
<br />
عملية استرداد التكاليف الخاصة بالمستخدم قد تستغرق 5-7 أيام عمل لكي يتم معالجتها علي بوابة الدفع الإلكتروني الخاصة ببطاقة الائتمان/الدين.
<br />
سوف نرسل بريدا ً إلكترونيا ً للمستخدم يحتوي علي مطبوعة لإعلان الإسترداد تم طبعها من خلال بوابة الدفع الإلكتروني لبطاقة الائتمان/الدين كمرجع للمستخدم في حال إذا أراد المستخدم أن يُعلم البنك. المستخدم يجب أن يتابع مع البنك في حالة أي تأخير في إرجاع المبلغ الذي دفعه المستخدم إلي حسابه.
<br />
المستخدمون الذين يستخدمون بوابة الدفع الإلكتروني يُطلب منهم التواجد علي أرقام التواصل الخاصة بهم.
<br />
المستخدم مسؤول مسؤولية كاملة عن عمل الطلبات من خلال خدمة بوابة الدفع الإلكتروني بعد قراءة شروط الخدمة هذه.
<br />
بطاقات الائتمان و/أو بطاقات الدين المستخدمة في الطلبات من خلال بوابة الدفع الإلكتروني علي المنصة يجب أن تكون مملوكة للمستخدم. وإذا كانت غير ذلك فيجب علي المستخدم أن يحصل علي الإذن القانوني من صاحب البطاقة لكي يتم التعامل.

<br /><br />

 

علامتنا التجارية مسجلة
<br /><br />
العلامة التجارية ل"البيت يجمعنا" هي علامة تجارية مسجلة حكوميا ً لشركة راز للمعارض. لا يحق لك استخدامها بدون إذن منا، إلا اذا كانت جزء من مواد تستخدمها كما هو مصرح به في هذه الشروط وبموافقة شركة راز للمعارض.
<br /><br />

السرية<br /><br />
5.1 أي معلومات تخص المستخدمين والسجلات الخاصة بهم قد يتم تمريرها لأطراف ثالثة. علي الرغم من ذلك فإن سجلات المستخدمين يتم إعتبارها وثائق سرية وبالتالي لن يتم الكشف عنها لأي طرف ثالث غير الموظفين وللسلطات المختصة في حالة استدعي الوضع القانوني ذلك.
<br />
المستخدمون لديهم الحق في طلب رؤية نسخ من أي أو كل سجلات المستخدم التي نحتفظ بها بشرط إعطاءنا إشعار بذلك الطلب قبل فترة معقولة. يُطلب من المستخدمين الإحتفاظ بنسخ لأي مواد مطبوعة بخصوص خدماتنا المقدمة. حينما يكون الوضع مناسبا ً سنقوم بتوزيع معلومات مكتوبة، بيانات، أو نسخ للسجلات كجزء من عقد يتم الإتفاق عليه وذلك لمصلحة الطرفين.
<br /><br />
5.2 نحن لن نبيع، نشارك، أو نؤجر معلوماتك الشخصية لأي طرف ثالث أو نستخدم بريدك الإلكتروني لإرسال رسائل غير مطلوبة. أي رسائل إلكترونية ترسلها منصة "البيت يجمعنا" ستكون مرتبطة بالخدمات والمنتجات المقدمة والمتفق عليها.
<br /><br />
مردود الخدمة<br /><br />
6.1 تستطيع تزويد منصة "البيت يجمعنا" بالمعلومات والمردود الخاص بالشكل والطريقة التي تقدم بها الخدمات بما يشمل تقارير الفشل، الأخطاء، أو أي أعطال أخري تواجهك حين استخدامك للخدمة بالإضافة لأي رسائل مصاحبة للأخطاء. مردود الخدمة الذي يتم توفيره ل"البيت يجمعنا" قد يتم استخدامه من قبل المنصة لتحسين الخدمة، وطبقا ً لذلك فأنت تعطي شركة راز للمعارض الرخصة والحق الغير حصري، الدائم، الغير قابل للإلغاء، المجاني، والعالمي لإستخدام، إعادة إنتاج، إعادة ترخيص، توزيع، تعديل واستغلال كامل للمردود المقدم بدون أي قيود.
<br /><br />
القطع المفقودة والتالفة<br /><br />
7.1 القطع المفقودة يجب أن يتم التبليغ عنها خلال 7 أيام من التسليم لفريق دعم "البيت يجمعنا". سيتم إعتبار القطع مفقودة بعد 20 يوم من الإبلاغ. التعويض عن أي عنصر مفقود يجب أن يكون محدودا ً ولا يتخطي عشرة أضعاف تكلفة تنظيف القطع المفقودة بغض النظر عن نوع الملابس وسعرها وحالتها. ولكن نحن لن نُعتبر مسؤولين عن أي قطع غير مرتبطة عند تقديمها كالساعات والمجوهرات والأزرار المعدنية وغيرها.
<br /><br />
7.2 نحن مسؤولون فقط عن دفع عشرة أضعاف ثمن تنظيف القطعة التالفة والإبلاغ عن هذا الأمر يجب أن يتم خلال 7 أيام من التسليم.
<br /><br />
نطاق توافر الخدمة<br /><br />
8.1 الخدمات المتوفرة من خلال هذه المنصة الإلكترونية متاحة فقط لعملائنا في السعودية، إلا إذا تم الإعلان عن غير ذلك. أنت مسؤول بمفردك عن تقييم مدي لياقة ومناسبة أي هدف معين لأي عملية تحميل، والبرامج والنصوص المتاحة من خلال هذه المنصة. إعادة توزيع أو إعادة نشر أي جزء من هذه المنصة أو محتواها ممنوع حتي ولو تمت إعادة صياغته- بدون التصريح الواضح المكتوب من قبل شركة راز للمعارض.
<br /><br />
منصة "البيت يجمعنا" لا تضمن أن خدمات المنصة لن تنقطع، أو تكون آنية وبلا أخطاء برغم محاولتنا الدائمة لتوفير أفضل خدمة. من خلال استخدامك للمنصة فأنت بالتالي تعفي "البيت يجمعنا" وموظفيها وعملائها من أي خسائر أو تلفيات تحدث بأي شكل أو طريقة أو كيفية.
<br />
الإشعارات: تعديل الشروط<br /><br />
9.1 نحن قد نوفر لك تنبيهات من خلال نشرهم علي المنصة أو من خلال البريد الإلكتروني الذي وفرته عندما سجلت بالخدمة. كل التنبيهات تأخذ حيز التنفيذ بمجرد نشرها أو إرسالها. بالإضافة إلي أننا قد نعدل شروط الاستخدام و/أو سياسة الخصوصية من خلال نشر النسخة المعدلة علي المنصة أو إرسال نسخة لك عن طريق البريد الإلكتروني.
<br />
من فضلك راجع الشروط بشكل دوري لأي تغييرات. من خلال استخدامك للخدمة بعد نشر أو إرسال أي من هذه التعديلات فأنت توافق علي أن تلتزم بالشروط المعدلة أو سياسة الخصوصية من تلك اللحظة فصاعدا ً. التغييرات المادية للشروط ستأخذ حيز التنفيذ بشكل أوتوماتيكي -من وقتها فصاعدا ً- بعد ثلاثين (30) يوما ً من نشرهم علي المنصة أو إرسالهم بالبريد الإلكتروني.
<br /><br />
ممتلكات الشركة<br /><br />
10.1 حقوقك كمستخدم للخدمات المتوفرة بالمنصة محدودة بالحقوق المعبر عنها في شروط الخدمة. أنت تدرك وتوافق أن منصة "البيت يجمعنا" والمرخص لهم منها يمتلكون كل الحق والملكية والفائدة في ومن الخدمة، بما في ذلك كل حقوق الملكية الفكرية حتي لو أدمجت الشركة والمرخص لهم منها أي مواد توفرها أنت كمستخدم في نسخ الخدمة المتتابعة.
<br /><br />
الخدمة محمية بحقوق النشر، والعلامة التجارية، والسر التجاري وقوانين أخري بالمملكة العربية السعودية والدول الأجنبية. إلا إذا تم التصريح بذلك في الشروط، فأنت لا تمتلك الحق لكي تعيد إنتاج أو تعدل أو تحضر أعمال مقتبسة أو تبيع أو تنقل أو تعرض أو تؤدي بشكل علني أو تبث أو تستخدم أو تستغل بأي شكل أي جزء من الخدمة بما يتضمن بشكل غير حصري أي معلومات يتم توفيرها من خلال الخدمة.
<br /><br />
10.2 محتوي الخدمة يتضمن مواد حقوق النشر بها محفوظة، وعلامات تجارية، وأسرار تجارية، ومعلومات أخري ممتلكة والتي قد تتضمن بشكل غير محدود: معلومات شخصية للمستخدمين، نصوص، برامج سوفتوير، صور، مواد مصورة(فيديو) مواد مسموعة ومسجلة، أعمال جرافيك، موسيقي وأصوات. محتويات الخدمة بالكامل وما يتفرع عنها هي أيضا ً ذات حقوق نشر محفوظة كأعمال مشتركة. لا يحق لك عمل قاعدة بيانات أو أي تجميع أرشيفي- بشكل إلكتروني أو مادي من خلال التنزيل/التحميل والتخزين أو التدوين أو الإستخدام بأي شكل يدوي أو آلي- لأي مواد تحتويها الخدمة.
<br />
محظورات عامة<br /><br />
11.1 أنت توافق أنك لن تفعل أو تحاول أن تفعل (أو تطلب أن طرفا ً ثالثا ً يفعل أو يحاول) أي من الآتي أثناء استخدامك/استخدامهم للخدمك أو علاقتك/م بها:
<br />
التحايل أو محاولة التحايل للدخول أو استخدام المحظورات أو المعلومات المشفرة أو المحتوي المحمي الذي يخص الخدمة.
<br />
العنكبة (إنشاء نسخ من جميع الصفحات التي يتم زيارتها لكى يفهرسها محرك البحث في وقت لاحق ويحمل الصفحات أثناء عمليات البحث) أو استخلاص البيانات، أو تجميع البريد الإلكتروني أو عناوين الإنترنت الهوائي، أو المحتوي المتوفر من خلال الخدمة بما في ذلك معلومات التواصل والمعلومات الشخصية أو أي طريقة آلية أخري للحصول علي قوائم للمستخدمين أو معلومات أخري من خلال الخدمة بما في ذلك- ولا يقتصر علي- أي معلومات متاحة علي أي موفر خدمة أو قاعدة بيانات مرتبطة بالخدمة.

<br /><br />

نسخ، تعديل أو توزيع الكود الحاسوبي المستخدم لتوليد صفحات الإنترنت علي الخدمة (إلا إذا تم التصريح بذلك من خلال إتفاقية أخري)
<br />
الدخول علي، أو التلاعب، أو استخدام أي جزء غير مخصص للجمهور بالخدمة، أو بأنظمة الكومبيوتر، أو الأنظمة التقنية للتسليم لموفري الخدمة خاصتنا.
<br />
اختراق أو مسح أو اختبار حساسية لأي نظام أو شبكة، أو خرق أي قواعد للأمان والمصادقة.
<br />
الدخول علي الخدمة أو البحث فيها من خلال أي محرك بحث أو برنامج أو أداة أو آلية غير السوفتوير والبرنامج والأدوات التي توفرها الشركة أو متصفحات شبكة توفري أطراف أخري.
<br />
إرسال بريد إلكتروني غير مطلوب، أو كمية كبيرة من البريد الإلكتروني، أو بريد إلكتروني غير مرغوب فيه، أو بريد إلكتروني مؤذي أو رسائل متسلسلة أو عروض أو إعلانات لمنتجات أو خدمات بما في ذلك- ولا يقتصر علي- الجهات المصممة لترويج بشكل مباشر أو غير مباشر لخدمة أو منتج ولديك أنت علاقة عمل أو أي صلة تجارية بهم.
<br />
استخدام الخدمة لإرسال معلومات مزورة أو مخادعة أو ذات مصدر مجهول بأي شكل أو طريقة.
<br />
فك شفرة أو تفكيك أو تقليد أي برنامج مستخدم في تقديم الخدمة.
<br />
التدخل أو محاولة التدخل في دخول أي مستخدم أو مستضيف أو شبكة بما في ذلك – ولا يقتصر علي- إرسال فيروسات، الإفراط في التحميل علي الخدمة، إغراقها بإرسال بريد إلكتروني مؤذي أو بكميات مهولة من البريد.
<br />
انتحال الشخصية أو إخفاء أو دعاء إرتباطك بأي شخص أو جهة.
<br />
استخدام الخدمة بما يخترق حقوق الملكية الفكرية ل"البيت يجمعنا" أو أي طرف ثالث أو أي حقوق قانونية أو ملكية.
<br />
استخدام الخدمة بما يخرق أي قانون مطبق أو طلب من موفري الخدمة توفير أي خدمة تخرق القانون المطبق.
<br />
رفع، نشر، إرسال بالبريد الإلكتروني، نقل، أو توفير مواد تخترق أو تختلس أي براءة اختراع أو علامة تجارية أو سر تجاري أو مواد ذات حقوق نشر محفوظة أو أي حقوق ملكية لأي طرف.
<br /><br />
12. إلغاء الطلب<br /><br />
12.1 أي طلب من الممكن أن يتم إلغاؤه في أي وقت قبل الإستلام بشرط ألا يتم هذا الإلغاء خلال ساعة من الإستلام وإلا فلن يتم إعادة دفع المبالغ المحصلة. في حالة الإلغاء خارج النطاق المحدد بساعة واحدة فالإلغاء مجاني إذا كان الدفع نقدي ولكن بالنسبة لبطاقات الإئتمان قد تكون هناك تكاليف للعملية.
<br /><br />
إيقاف الاستخدام: عدم استكمال وتغيير الخدمة<br /><br />
13.1 إذا خرقت أي شرط من شروط الخدمة فإن التصريح الذي تملكه لإستخدام الخدمة سيتم إيقافه بشكل أوتوماتيكي. أنت توافق أيضا ً أننا قد- في أي وقت وبدون تنبيه- نوقف أو نلغي دخولك إلي الخدمة واستخدامك لها أو أي حساب/حسابات تمتلكها مرتبطة بالخدمة: (1) لأي سبب أو بلا أي سبب علي الإطلاق. (2) عندما نقرر بشكل سري منفرد بأنه هذا التصرف له أسبابه لكي تلتزم بالمتطلبات القانونية أو لحماية حقوق ومصالح منصة "البيت يجمعنا" أو أي طرف ثالث. (3) بسبب أي عدم استكمال أو توقف للخدمة. نحن أيضا ً نحتفظ بالحق في تعديل الخدمة في أي وقت بدون تنبيه لك. نحن لن نتحمل أي مسؤولية فيما يخص أي تغيير في الخدمة أو أي توقف أو إلغاء لدخولك عليها أو استخدامك لها. أنت تستطيع إيقاف هذه الإتفاقية- مع إيقاف حسابك- في أي وقت من خلال التواصل مع خدمة عملاء "البيت يجمعنا".
<br /><br />
التعويض<br /><br />
14.1 أنت توافق علي أنك ستكون مسؤولا ً بشكل شخصي عن استخدامك للخدمة وتوافق أن تحمي وتعفي وتبرئ شركة راز للمعارض وموظفيها ومدرائها والعاملين بها ومستشاريها وتابعيها وشركاتها الفرعية وعملائها (بشكل عام: هيئات "البيت يجمعنا") من وضد أي وكل الدعاوي، المسؤوليات، التلفيات، الخسائر والتكاليف- بما في ذلك تكاليف المحاماة والمحاسبة- التي قد تنشأ عنك أو مرتبطة بـ: (1) دخولك علي، استخدامك/ المزعوم للخدمة. (2) خرقك لشروط الخدمة أو أي بيان أو ضمان أو اتفاقيات مذكورة هنا. (3) خرقك لأي حقوق لطرف ثالث بما يشمل- ولا يقتصر علي- أي حقوق ملكية فكرية، دعاية إعلانية، أسرار، حق الإمتلاك أو الخصوصية. (4) أي شكوي أو إدعاء لطرف ثالث تحوله شركة راز للمعارض إليك إلي الحد المبني علي أي فعل حقيقي أو مزعوم أو إهمال من طرفك وجانبك، أو أي دعوي أو إدعاء ينتج عن ذلك التحويل. (5) أي خلافات أو مشاكل بينك وبين أي موفر للخدمة أو طرف ثالث. نحن نحتفظ بالحق- علي حسابنا الخاص- لتولي الدفاع والتحكم في أي أمر عرضة للتعويض بواسطتك، وفي تلك الحالة فأنت توافق أن تتعاون مع دفاعنا عن تلك الدعوي. تحت أي ظرف أنت لا تسوي أو تتعامل مع أي دعوي أو موضوع بدون إذن مكتوب منا.

 
<br /><br />
استخدامك للخدمة هو علي مسؤوليتك ومخاطرتك الشخصية<br /><br />
15.1 الخدمة يتم توفيرها “كما هي” وكما هي متاحة بدون أي ضمان أو شرط من أي نوع سواء معلن أو غير معلن. جهات "البيت يجمعنا" تنكر بشكل محدد –لا يقتصر علي- (1) أي ضمانات مبطنة، صلاحية الخدمة لغرض بعينه، الإستمتاع الهادئ أو عدم المخالفة. (2) أي ضمانات تنبثق عن التعامل، الاستخدام، أو التجارة. (3) أي ضمانات أن المعلومات أن النتائج المتوفرة من قبل الخدمة -أو قد يتم الحصول عليها من استخدامها- أو الأعمال المطلوبة أو المؤداة فيما يخص الخدمة ستلبي احتياجاتك أو تكون دقيقة أو يعتمد عليها أو كاملة أو حديثة. (4) أي ضمانات أو بيانات أو وعود أو ما يشبهذلك من تصريحات يصرح بها موفر الخدمة و (5) أي ضمانات تخص أي منتجات أو الخدمات أو المعلومات أو مواد أخري يتم الإعلان عنها أو توفيرها أو الإشارة لها إليك من خلال الخدمة. أنت تتحمل كل مخاطر التلفيات بما فيها التلفيات المتعلقة بالطلبات أو المتعلقة بنظام الكومبيوتر الخاص بك أو فقدانك للبيانات الذي قد ينتج عن استخدامك أو دخولك علي الخدمة. أي محتوي أو مواد أو معلومات أو برامج يتم تحميلها، استخدامها، أو الحصول عليها من خلال الاستخدام يتم ذلك من خلال تقديرك ومخاطرتك الشخصية.
<br /><br />
حدود المسؤولية والإعفاء<br /><br />
16.1 للحد الأقصي الذي يسمح به القانون المطبق فإن جهات "البيت يجمعنا" وأي جهة مشتركة في عمل أو إنتاج أو تشغيل أو توصيل الخدمة لن تكون مسؤولة عن أي تلفيات – مباشرة أو غير مباشرة أو عرضية أو خاصة أو ذات عواقب أو يعاقب عليها القانون- يسببها استخدامك للخدمة أو عدم قدرتك علي استخدام الخدمة سواء بناءا ً علي الضمان أو التعاقد أو الضرر(يشمل الإهمال) أو التشريع أو أي نظرية قانونية. سواء تم إخبار جهات "البيت يجمعنا" بإحتمالية هذا الضرر أو لا، حتي ولو فشل الحل المقترح هنا في أداء وظيفته الأساسية.
<br />
أن توافق أن جهات "البيت يجمعنا" غير مسؤولة عن أي تصرف افترائي أو عدائي أو غير قانوني يسببه موفرو الخدمة أو المستخدمون الآخرون أو أطراف ثالثة، وأن خطر التعامل مع المذكورين يقع علي عاتقك. نحن غير مسؤولون عن اعتمادك علي معلومات يتم توفيرها من قبل موفر للخدمة أو من خلال الخدمة ويجب عليك أن تتأكد من صحة مثل هذه المعلومات، أنها دقيقة وكاملة ومناسبة لك. أنت مسؤول عن التعامل مع موفر الخدمة لتحديد طبيعة وحجم كل طلب ولتأكيد أن مثل هذا الطلب يتوافق مع كل القوانين المطبقة.
<br />
الطلبات/الأعمال قد تكون خطيرة بشكل كبير وقد تؤدي إلي فقدان أو أذي للملكية أو الأذي الشخصي أو الموت. جهات "البيت يجمعنا" لن تكون مسؤولة تجاهك أو تجاه أي طرف ثالث في حالة أي من هذه الأحداث. جهات "البيت يجمعنا" لن تكون مسؤولة تجاهك أو تجاه أي طرف ثالث عن أي محتوي يرفعه طرف ثالث للمنصة أو ينزله/يحمله منها أو من خلالها.
<br /><br />
16.2 في حالة كان لديك خلاف يخص طلب ما طلبته من منصة "البيت يجمعنا" فأنت بناءا ً علي هذا البند تعفي جهات "البيت يجمعنا" من أي وكل الدعاوي والتلفيات (الحالية والناتجة) والإلتزامات والخسائر والمسؤوليات والتكاليف والديون والمصاريف – تشمل ولكن لا تقتصر علي- مصاريف المحاماة  من أي نوع أو طبيعة معروفة أو مجهولة، مشكوك بها أو غير مشكوك بها، مكشوفة أو غير مكشوفة – فيما ينتج بأي شكل عن مثل ذلك الخلاف.
<br /><br />
16.3 أنت توافق أن المسؤولية الإجمالية لجهات "البيت يجمعنا" تجاهك كمستخدم عن أي وكل الدعاوي التي تنشأ عن استخدام الخدمة أو مرتبطة بها محدودة بما يلي: (1) المبالغ التي دفعتها ل"البيت يجمعنا" مقابل الدخول علي الخدمة واستخدامها(بما في ذلك المبالغ التي دفعتها للطلبات) خلال الستة أشهر (6 أشهر) التي تسبق الحدث الذي أدي إلي الدعوي.. أو (2) عشرة أضعاف المبلغ الذي دفعته للخدمة. حدود التعامل فيما يخص التلفيات الموضحة بالأعلي هي عناصر أساسية للإتفاق بين الشركة وبينك.
<br /><br />
حل الخلافات والتحكيم<br /><br />
17.1 بشكل عام ولصالح حل الخلافات بينك وبين "البيت يجمعنا" بأكثر الوسائل النفعية والإقتصادية فإنك وشركة راز للمعارض توافقان علي أن أي وكل الخلافات التي تنشأ عن هذه الإتفاقية سوف يتم حلها من خلال التحكيم الملزم وهو طريقة أقل رسمية من القضايا القانوية في المحكمة ويستخدم محكم محايد بديلا ً عن القاضي أو هيئة المحلفين. المحكم المحايد يستطيع إعطاء نفس التعويضات التي قد تحكم بها المحكمة. اتفاقنا للتحكيم الملزم تشمل – ولكن لا تقتصر علي- كل الدعاوي التي قد تنشأ عن بعض تفاصيل هذه الشروط سواء بناءا ً علي –أو بسبب- التعاقد أو التقصير في المسؤولية أو التشريع أو الغش أو الخداع أو أي نظرية قانونية أخري سواء حدثت الدعوي أثناء أو بعد إنتهاء هذه الشروط. أنت تفهم وتوافق علي أنه بدخولك هذه الإتفاقية فإنك وشركة راز للمعارض تتخليان عن حق الإحتكام أمام هيئة قضائية. علي الرغم من السابق ذكره بهذا البند فكلانا يتفق علي أن لا شئ هنا سيجعلنا نتنازل أو يمنعنا أو يحد من حقوقنا في: (1) الذهاب بقضية فردية للمحكمة. (2) السعي لتطبيق الإجراءات الإنفاذية من خلال المدينة أو المقاطعة أو الجهات المحلية حينما تتوفر مثل هذه الإجراءات. (3) الحصول علي إنذار قضائي من محكمة. أو (4) الذهاب للمحكمة لرفع قضية انتهاك لحقوق الملكية الفكرية.
<br /><br />
17.2 الطرف الذي يرغب في التحكيم يجب عليه أولا ً أن يرسل إخطار مكتوب بشأن الخلاف للطرف الآخر من خلال البريد المعتمد أو من خلال البريد الإلكتروني في حالة عدم وجود عنوان مادي.
<br /><br />
(“الإخطار”)
<br /><br />
17.3 الإخطار يجب أن (أ) يصف طبيعة وأساس الدعوي أو الخلاف، و (ب) يحدد التعويض المطلوب (“الأمر”) نحن نتفق علي استخدام النوايا الحسنة لحل الدعوي مباشرة ولكن إذا لم نصل لإتفاق خلال 30 يوما ً بعد استقبال الإخطار فأنت أو "البيت يجمعنا" من الممكن أن تشرعا في إجراءات التحكيم. خلال التحكيم مبلغ أي عرض تسوية منك أو من شركة راز للمعارض لن يتم الإفصاح عنه للمحكم حتي يقوم المحكم بأخذ قرار نهائي، إذا حدث وانتهي التحكيم لصالحك فشركة راز للمعارض (1) ستدفع لك أكبر مبلغ يحكم به المحكم و (2) أكبر مبلغ معروض من قبل "البيت يجمعنا" للتسوية للخلاف قبل حكم المحكم.
<br /><br />
القانون الحاكم ومكان الدعاوي<br />
18.1 هذه القوانين سواء تم تفسيرها بقاعة محكمة أو من خلال التحكيم الملزم فسوف تحكمها قوانين المملكة العربية السعودية. أي نزاع أو دعوي قضائية تنشأ من خلال أو بالإرتباط بهذا التطبيق سوف يتم الحكم فيه وتفسيره بما يتماشي مع قوانين المملكة العربية السعودية. المملكة العربية السعودية هي بلد المقر.
<br /><br />
شروط عامة<br /><br />
19.1 شروط الخدمة بالإضافة إلي سياسة الخصوصية تشكل الاتفاق والتفاهم الكامل والوحيد بينك وبين "البيت يجمعنا" فيما يخص استخدامك للخدمة ودخولك عليها ويتم التعديل في هذا الإتفاق من خلال اتفاقية مكتوبة وموقعة من ممثلين مخولين من الطرفين. لا يحق لك أن تخصص أو تحول شروط الخدمة أو حقوقك المذكورة أدناه بشكل كلي أو جزئي، من خلال عملية قانونية أو غيرها، بدون إذن مكتوب مننا. نحن قد نحول ملكية شروط الخدمة في أي وقت وبدون إشعار أو تنبيه. الفشل في التصرف علي أساس أي شرط من شروط الخدمة لن يؤثر في حقنا في طلب التصرف بموجب ذلك الشرط خلال أي وقت بعدها. ولن يشكل أي تنازل عن أي ثغرة أو إهمال بالشروط ثغرة لخروقات أخري أو إهمال للشروط نفسها. استخدام رؤوس للبنود في شروط الخدمة هو فقط للتسهيل ولن يكون له أي تأثير علي تفسير أي بند بعينه. في حالة أن أي جزء من هذه الشروط اتضح أنه غير صالح أو غير قابل للتنفيذ فإن ذلك الجزء سيتم تطبيقه بقدر الإمكان وباقي الشروط تعمل بشكل كامل وكلي كما هي.
                </Paragraph>
                <Footer />
            </Content>
        </Container>
    );
};