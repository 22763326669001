import React from 'react';

import Header from '../Header'
import Footer from '../Footer'
import { Container, Content, Buttons, Family, Head1, Head2, Paragraph, Button } from './styles';
import { AiFillAndroid, AiFillApple } from "react-icons/ai";

export default () => {
    return (
        <Container>
            <Content>
                <Header />
                <Head1>إتفاقية الخصوصية</Head1>
                <Paragraph>
                    تم تحديث بيان الخصوصية في تاريخ :٢٠ مايو ٢٠٢٠.
                    <br />
                    <br />
                    
                    ندرك مدى أهمية الخصوصية بالنسبة لك. وبناءً عليه فقد قمنا بوضع سياسة الخصوصية (المشار إليها هنا فيما بعد باسم "السياسة") تشمل كيفية جمع بياناتك الشخصية واستخدامها والإفصاح عنها وحمايتها وحفظها وتحويلها. يُرجى التوقف برهة لقراءة سياسة الخصوصية وفهمها
                    يُقصد بالبيانات الشخصية مجموعة متنوعة من المعلومات المُسجلة إلكترونيًا أو غير ذلك بحيث يمكنك استخدامها بشكل مفرد أو من خلال جمعها مع معلومات أخرى للتعرف على هوية شخصٍ معين. يوضح هذا البيان كيفية معالجتنا لبياناتك الشخصية، ولكنه لا يغطي كافة الاحتمالات، حيث يتم إبلاغك بتلك الأنشطة في حينه. وعليه، فإننا ننصحك بالاطلاع على إشعار الخصوصية أو أي بيان مكمل صادر من استخدام التطبيق، وذلك لكي يتسنى لك فهم الآلية التي يتم بها معالجة بياناتك الشخصية، وكيف يمكنك ممارسة حقوق موضوع بياناتك، وكيفية الاتصال بالمتحكم في البيانات.
                    
<Head2>
                        ١.  نطاق معالجة البيانات الشخصية
</Head2>
                    بصفة عامة، لا نُسجِّل سوى البيانات الشخصية التي تكشف عنها عند استخدام الخدمات كجزء من عمليتي تسجيل الدخول أو التسجيل، وربما خلال استخدام الخدمات المقدمة. والبيانات الشخصية المعنية هي تلك البيانات التي تحتوي على معلومات عن الظروف الشخصية أو الواقعية. فعند تسجيل الدخول أو التسجيل كمستخدم على تطبيقنا لا نطلب منك سوى تقديم عنوان بريد إلكتروني و، إذا اقتضى الأمر، اسم مستخدم وكلمة مرور. وعند التسجيل في خدمات معينة، لا نجمع عناوين البريد الإلكتروني أثناء التسجيل، لكن نجمعها في وقت لاحقٍ أثناء استخدام الخدمة، ونُخزِّن كلمة المرور في صورة مُشفرة، لا تسمح أبدًا بالاستدلال على كلمة المرور الفعلية.
                    
<Head2>
                        ٢. حذف البيانات ومدة تخزينها
</Head2>
                    نحذف أو نحظر البيانات الشخصية لصاحب البيانات فور انقضاء الغرض من التخزين. بالإضافة إلى ذلك، كما تحدث عمليتا حجز البيانات أو حذفها أيضًا عند انقضاء مدة التخزين المنصوص عليها في اللوائح المشار إليها بعاليه، ما لم يكن تخزين البيانات مدة إضافية مطلوبًا لإبرام أحد العقود أو تنفيذها.
                    
<Head2>
                        ٣. أمن البيانات
</Head2>
                    نبذل الجهود المناسبة لمنع الوصول غير المصرح به إلى بياناتك الشخصية، فضلا عن استخدام هذه البيانات استخداما غير مصرح به أو تحريفها وتقليل المخاطر المصاحبة لها. ومع ذلك، دائمًا ما ينطوي إعطاء البيانات الشخصية، سواء كان ذلك بصورة شخصية أو عبر الهاتف أو الإنترنت، على مخاطر ولا يخلو نظام تكنولوجي تماما من إمكانية التلاعب به أو تخريبه.
                    نعالج المعلومات التي تجمعها منك وفقًا للقانون٫ ويلتزم جميع الموظفين بالامتثال لأحكام سرية البيانات وحمايتها ونعمل على توجيههم في هذا الخصوص حيث تُرسل بياناتك في صيغة مُشفرة باستخدام بروتوكول SSL.
                    
<Head2>
                        ٤. مدة التخزين
</Head2>
                    نحذف البيانات فور انتهاء الغرض الذي جُمعت من أجله.
                    
<Head2>
                        ٥. الغرض من معالجة البيانات
</Head2>
                    الإعلامات المنبثقة عبارة عن إعلامات خاصة تُعرَض على جهازك المحمول مباشرة. وتحتوي هذه الإعلامات مثلا على معلومات عن استكمال أحد منشآتك في اللعبة. وكقاعدة عامة، تحتوي الإعلامات المنبثقة على رسائل قصيرة تركز على العناصر الأساسية.
                    
<Head2>
                        ٦. إمكانية الاعتراض والإزالة
</Head2>
                    ‌أ) نظام تشغيل Android
                    افتح الإعدادات > التطبيقات والإعلامات > الإعلامات > إعلامات التطبيقات > اسم التطبيق. على هذه الشاشة، يمكنك التحكم في ظهور الإعلامات المنبثقة من عدمه وفي كيفية ظهورها.
                    ‌ب) نظام تشغيل iOS
                    افتح الإعدادات > الإعلامات > اسم التطبيق. على هذه الشاشة، يمكنك التحكم في ظهور الإعلامات المنبثقة من عدمه وفي كيفية ظهورها.
                    
<Head2>
                        الأحكام النهائية
 </Head2>
                    يجوز أن تعدل أحكام حماية البيانات في أي وقت، على أن تُخطِر بأيٍ من هذه التغييرات عبر القنوات المناسبة.
                    
                </Paragraph>
                <Footer />
            </Content>
        </Container>
    );
};