import React from 'react';
import styled from 'styled-components';

import colors from '../../configs/colors'

export const Container = styled.div`
    background-color: transparent;
    font-size: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 52px;
`

export const Paragraph = styled.p`
    font-size: 9px;
`

export const Menu = styled.div`
    font-size: 12px;
    a {
        margin: 0 8px;
        color: ${colors.accent};
        text-decoration: none;
    }
`