import React from 'react';

import Header from '../Header'
import Footer from '../Footer'
import { Container, Content, Buttons, Family, Head1, Head2, Paragraph, Button } from './styles';
import { AiFillAndroid, AiFillApple } from "react-icons/ai";

export default () => {
    return (
        <Container>
            <Content>
                <Header />
                <Head1>شارك مع عائلتك اللعب، وتحدى جيرانك.</Head1>
                <Paragraph>حمل تطبيق البيت يجمعنا الآن</Paragraph>
                <Buttons>
                    <Button href="https://apps.apple.com/us/app/%D8%A7%D9%84%D8%A8%D9%8A%D8%AA-%D9%8A%D8%AC%D9%85%D8%B9%D9%86%D8%A7/id1514504912?ls=1"><AiFillApple /> حمل التطبيق للآيفون</Button>
                    <Button><AiFillAndroid />قريبًا على الأندرويد</Button>
                </Buttons>
                <Footer />
            </Content>
        </Container>
    );
};