import React from 'react';
import { Container, Paragraph, Menu } from './styles';
import { Link } from "react-router-dom";
export default () => {
	return (
		<Container>
			<Menu>
				<Link to="/">الرئيسية</Link> <Link to="/terms">إتفاقية الإستخدام</Link> <Link to="/privacy">إتفاقية الخصوصية</Link>
			</Menu>
			<Paragraph>ميادين. جميع الحقوق محفوظة © {new Date().getFullYear()} Squares.  All rights reserved</Paragraph>
		</Container>
	);
};