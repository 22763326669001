import React from 'react';
import styled from 'styled-components';

import colors from '../../configs/colors'

export const Container = styled.div`
    background-color: ${colors.primary};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url(/assets/images/back.png);
    background-size: contain;
    background-position: 80% center;
    background-repeat: no-repeat;
    z-index: 1;
`

export const Content = styled.div`
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url(/assets/images/family.png);
    background-size: auto 50%;
    background-position: 90% 100%;
    background-repeat: no-repeat;
    z-index: 1;
    overflow-y: scroll;
`

export const Family = styled.img`
    width: 100px;
    height: auto;
    margin: 0 0 52px;
`

export const Head1 = styled.h1`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 24px;
`

export const Head2 = styled.h2`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 20px;
`

export const Paragraph = styled.p`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 16px;
`

export const Button = styled.a`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 16px;
    background-color: ${colors.accent};
    color: white;
    padding: 16px 24px;
    margin: 0 8px;
    border-radius: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 200px;

    text-decoration: none;

    svg {
        font-size: 24px;
        margin: 0 0 0 16px;
    }

    &:nth-child(2) {
        background-color: white;
        color: ${colors.secondary};
    }

    @media only screen and (max-width: 600px) {
        margin: 8px 0px;
    }
`

export const Buttons = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`