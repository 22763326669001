import React from 'react';
import styled from 'styled-components';

import colors from '../../configs/colors'

export const Container = styled.div`
    background-color: ${colors.primary};
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url(/assets/images/back.png);
    background-size: contain;
    background-position: 80% center;
    background-repeat: no-repeat;
    z-index: 1;
`

export const Content = styled.div`
    background-color: transparent;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
`

export const Family = styled.img`
    width: 100px;
    height: auto;
    margin: 0 0 52px;
`

export const Head1 = styled.h1`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 24px;
`

export const Head2 = styled.h2`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 20px;
`

export const Paragraph = styled.p`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 16px;
`

export const Button = styled.a`
    font-family: MainFont, 'Roboto', sans-serif;
    font-size: 16px;
    background-color: ${colors.accent};
    color: white;
    padding: 16px 24px;
    margin: 0 8px;
    border-radius: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 24px;
        margin: 0 0 0 16px;
    }

    &:nth-child(2) {
        background-color: white;
        color: ${colors.secondary};
    }
`

export const Buttons = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`