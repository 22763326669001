import React from 'react';

import Home from './Home'
import Privacy from './Privacy'
import Terms from './Terms'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";

export default () => (
	<div dir="rtl">
		<Router>
			<Switch>
				<Route path="/privacy">
					<Privacy />
				</Route>
				<Route path="/terms">
					<Terms />
				</Route>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</Router>
	</div>
);