import React from 'react';
import styled from 'styled-components';

import colors from '../../configs/colors'

export const Container = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Logo = styled.img`
    width: 150px;
    height: auto;
    margin: 0 0 52px;
`
